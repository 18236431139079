










































import { Component, Model, Vue } from 'vue-property-decorator';
import { ListItem, ListItemPart, Overlay, Pagination } from '../components';
import {Org, Dict, Comp, Utils} from '../types';
import { store } from '../common';
import I18n from "@/modules/budget/staffing-table/I18n";
import BudgetFormsList from "@/modules/budget-request/components/budget-forms-list.vue";
import {Ax} from "@/utils";
import auth from "@/services/auth";
const noValue = Symbol('modules/budget/staffing-table/components/SingleKgkpSelection.vue :: No value');
const modelChangeEvent = 'change';
const i18n = new I18n('modules.budget.staffing_table.components.SingleKgkpSelection');
@Component({
    components: {
        BudgetFormsList,
        'list-item': ListItem,
        'list-item-part': ListItemPart,
        'overlay': Overlay,
        'pagination': Pagination,
    },
})
export default class SingleKgkpSelection extends Vue {

    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: noValue,
        },
    )
    public value!: Org.Kgkp | null | symbol;


   private created () {
       // region Model, properties
       this.$watch('value', (value: Org.Kgkp | null | symbol) => {
           if (typeof value === 'symbol') return;
         if (this.localValue?.code ! == value?.code){
             this.localValue = value;
         }
       })
       // endregion

       // region КГКП list
       this.$watch('selectedAbp', (selectedAbp: Dict.EbkFunc | null) => {
           if (selectedAbp !== null) {
               store.abp = selectedAbp;
               this.reloadKgkpList()
           }
       });
       // endregion

       // region Kgkp list
       this.$watch('itemsPerPage', () => {
           if (this.page === 0) {
               this.reloadKgkpList();
           } else {
               this.page = 0;
           }
       });
       this.$watch('page', () => {
           this.reloadKgkpList();
       });

       this.$watch('localValue', (localValue: Org.Kgkp | null) => {
           if ((typeof this.value === 'symbol') || (this.value?.code !== localValue?.code)) {
               this.$emit(modelChangeEvent, localValue);
           }
       });
   }
    // endregion

    private mounted() {
        if (typeof this.value !== 'symbol') {
            this.localValue = this.value;
        }
        this.reloadAbpList();
    }

    // region Utils
    private i18n = i18n;

    private get isKazakh(): boolean {
        return this.i18n.isKazakh;
    }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // endregion

    // region Misc data
    private get userId(): string {
        return auth.user.sub;
    }

    private localValue: Org.Kgkp | null = null;

   private get loading () {
       return (this.abpLoading || this.kgkpLoading)
   }

    private abpLoading = false;

    // region ABP list
    private abpList: Array<Dict.EbkFunc> = [];

    private page = 0;

    private totalKgkpCount = 0;

    private itemsPerPage = 25;

    private itemsPerPageChange(value: number) { this.itemsPerPage = value; }

    private getKgkpItemVariate(kgkp: Org.Kgkp): Comp.ColorVariant | null {
        return (kgkp.code === this.localValue?.code) ? 'primary' : null;
    }

    private onKgkpClick(kgkp: Org.Kgkp) {
        if (kgkp.code !== this.localValue?.code) {
            this.localValue = kgkp;
        }
    }

    private getKgkpDetails (kgkp: Org.Kgkp): { bin: string, id: string, name: string  } {
      const id = kgkp.kgkp.id.toString()
      const bin = kgkp.code
      const name = this.i18n.isKazakh ? kgkp.nameKk : kgkp.nameRu;

      return { id, bin, name }
    }

    private get abpOptions(): Array<Comp.DropdownItemDef<Dict.EbkFunc>> {
        const isKazakh = this.isKazakh;

        return this.abpList.map(abp => {
            let text: string;
            if (isKazakh) {
                text = abp.nameKk || abp.nameRu || (abp.id + '');
            } else {
                text = abp.nameRu || abp.nameKk || (abp.id + '');
            }

            let code = (abp.abp?.toString() || '');
            while (code.length < 3) {
                code = '0' + code;
            }

            return {
                value: abp,
                text: `${code} - ${text}`,
            };
        });
    }

    private selectedAbp: Dict.EbkFunc | null = null

    private reloadAbpList() {
        if (this.abpLoading) {
            console.error('Cannot load ABP list - another loading is running');
            return;
        }

        const userId = this.userId;
        this.abpLoading = true;

        Ax<Array<Dict.EbkFunc>>(
            {
                url: `/api/budget/staffing_table/abp/kgkp/list-for-user/${userId}`,
            },
            data => {
                this.abpList = data;

                const savedAbp = store.abp;
                if (savedAbp === null) {
                    this.selectedAbp = null;
                } else {
                    const selectedAbp = data.find((loadedAbp) => {
                        return (
                            (loadedAbp.gr === savedAbp.gr)
                            &&
                            (loadedAbp.pgr === savedAbp.pgr)
                            &&
                            (loadedAbp.abp === savedAbp.abp)
                        );
                    });

                    this.selectedAbp = (selectedAbp ?? null);
                }
            },
            error => {
                this.toast('danger', this.i18n.translate('cannot_load_abp'), error.toString());
            },
            () => {
                this.abpLoading = false;
            },
        );
    }
    // endregion

    // noinspection JSMismatchedCollectionQueryUpdate
    private kgkpList: Array<Org.Kgkp> = [];

    private kgkpLoading = false;

    private reloadKgkpList() {
        if (this.kgkpLoading) {
            console.error('Cannot load KGKP list - another loading is running');
            return;
        }

        const selectedAbp = this.selectedAbp;
        if (selectedAbp === null) {
            console.error('Cannot load KGKP list - no selected ABP');
            return;
        }

        this.kgkpLoading = true;

        Ax<Utils.PaginationList<Org.Kgkp>>(
            {
                url: `/api/budget/staffing_table/kgkp/list?items-per-page=${this.itemsPerPage}&page=${this.page}&user-id=${this.userId}&abp-code=${selectedAbp.abp}`,
            },
            data => {
                if (this.page !== data.page) {
                    setTimeout(() => {
                        this.page = data.page;
                    });
                    return;
                }


                this.totalKgkpCount = data.itemCount;
                this.kgkpList = data.items;

                const savedKgkp = this.value;
                if ((typeof savedKgkp === 'symbol') || (savedKgkp === null)) {
                    this.localValue = null;
                } else {
                    const localValue = data.items.find((loadedKgkp) => {
                        return (loadedKgkp.code === savedKgkp.code);
                    });

                    this.localValue = (localValue ?? null);
                }
            },
            error => {
                this.toast('danger', this.i18n.translate('cannot_load_kgkp'), error.toString());
            },
            () => {
                this.kgkpLoading = false;
            },
        );
    }
}
