



























import { Component, Model, Vue } from 'vue-property-decorator';
import { Comp, Org } from '../types';
import Overlay from '../components/Overlay.vue';
import SingleGuSelection from './SingleGuSelection.vue';
import SingleKgkpSelection from "@/modules/budget/staffing-table/organizations/SingleKgkpSelection.vue";

const modelChangeEvent = 'change';
const noValue = Symbol('src/modules/budget/staffing-table/components/SingleOrgSelection.vue :: No value');


@Component({
    name: 'SingleOrgSelection',
    components: {
        'kgkp-selection': SingleKgkpSelection,
        'overlay': Overlay,
        'gu-selection': SingleGuSelection,
    },
})
export default class SingleOrgSelection extends Vue {
    // region Model, properties
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: false,
            default: noValue,
        },
    )
    public value!: Org | null | symbol;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        // region Model, properties
        this.$watch('value', (value: Org | null | symbol) => {
            if ((typeof value !== 'symbol') && (this.localValue?.code !== value?.code)) {
                this.localValue = value;
            }
        });
        // endregion


        // region Organization types
        this.$watch('orgType', (orgType: Org.OrgType) => {
            if ((this.localValue !== null) && (this.localValue.type !== orgType)) {
                this.localValue = null;
            }
        });
        // endregion


        // region Data
        this.$watch('localValue', (localValue: Org | null) => {
            if ((typeof this.value === 'symbol') || (this.value?.code != localValue?.code)) {
                this.$emit(modelChangeEvent, localValue);
            }
        });
        // endregion
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (typeof this.value !== 'symbol') {
            if (this.value !== null) {
                this.orgType = this.value.type;
            }

            this.localValue = this.value;
        }
    }
    // endregion


    // region Organization types
    private get orgTypeOptions(): Array<Comp.DropdownItemDef<Org.OrgType>> {
        return [
            {
                text: 'ГУ',
                value: 'GU',
            },
            {
                text: 'КГКП',
                value: 'KGKP',
            },
        ];
    }

    private orgType: Org.OrgType = 'GU';
    // endregion


    // region Data
    private localValue: Org | null = null;
    // endregion
}
